<template>
  <div v-if="isShow">
    <div v-for="item in childValue" :key="item.pic_id">
      <h2 style="font-size: 16px;">{{ getText(item.pic_name.split("#")[1] || '') }}</h2>
      <!--  视频首页图模式 -->
      <!--<Video :src="item.pic_path" :poster="poster" :width="screenWidth" :height="screenHeight"/>-->
      <!--  视频帧率模式 -->
      <Video :src="item.pic_path" :second="0" :width="screenWidth" :height="screenHeight" />
    </div>
  </div>
</template>
<script>
import Video from '@/components/Video'
export default {
  name: 'VideoPlay',
  components: {
    Video
  },
  // props: {
  //   childValue: { // 视频文件url集合，必传，支持网络地址 https 和相对地址 require('@/assets/files/Bao.mp4')
  //     type: Object,
  //     required: true,
  //     default: () => {}
  //   }
  // },
  data () {
    return {
      isShow: false,
      childValue: [],
      screenWidth: 0,
      screenHeight: 0,
      // src: require('@/assets/files/Bao.mp4'), // 相对路径
      // poster: require('@/assets/images/Bao.jpg'),
    }
  },
  created() {
    if (this.$route.query.childValue.length !== 0) {
      this.isShow = true;
      this.childValue = this.$route.query.childValue;
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth - 16;
    window.onresize = () => {
      this.screenWidth = window.innerWidth - 16;
      this.screenHeight = this.screenWidth / 1.8;
    };
    this.screenHeight = this.screenWidth / 1.8;
  },
  methods: {
    getText(data) {
      return data ? data + ' ：' : '';
    }
  }
}
</script>